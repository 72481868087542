// const images = require('./images/*.jpg');

import gallery1 from "../images/gallery/gallery-1.jpg";
import gallery2 from "../images/gallery/gallery-2.jpg";
import gallery3 from "../images/gallery/gallery-3.jpg";
import gallery4 from "../images/gallery/gallery-4.jpg";
import gallery5 from "../images/gallery/gallery-5.jpg";
import gallery6 from "../images/gallery/gallery-6.jpg";
import gallery7 from "../images/gallery/gallery-7.jpg";
import gallery8 from "../images/gallery/gallery-8.jpg";
import gallery9 from "../images/gallery/gallery-9.jpg";
import gallery10 from "../images/gallery/gallery-10.jpg";
import gallery11 from "../images/gallery/gallery-11.jpg";
import gallery12 from "../images/gallery/gallery-12.jpg";

// Beta

function toggleSlideover() {
    document.getElementById('slideover-container').classList.toggle('invisible');
    document.getElementById('slideover-bg').classList.toggle('opacity-0');
    document.getElementById('slideover-bg').classList.toggle('opacity-50');
    document.getElementById('slideover').classList.toggle('translate-y-full');

    var element = document.getElementById("body");
    element.classList.toggle('noscroll');
}

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#toggleButton1');
    toggleButton.addEventListener('click', toggleSlideover);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#toggleButton2');
    toggleButton.addEventListener('click', toggleSlideover);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover-bg');
    toggleButton.addEventListener('click', toggleSlideover);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover');
    toggleButton.addEventListener('click', toggleSlideover);
});

// Battery

function toggleSlideoverBattery() {
    document.getElementById('slideover-battery-container').classList.toggle('invisible');
    document.getElementById('slideover-battery-bg').classList.toggle('opacity-0');
    document.getElementById('slideover-battery-bg').classList.toggle('opacity-50');
    document.getElementById('slideover-battery').classList.toggle('translate-y-full');

    var element = document.getElementById("body");
    element.classList.toggle('noscroll');
}

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#batteryButton1');
    toggleButton.addEventListener('click', toggleSlideoverBattery);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#batteryButton2');
    toggleButton.addEventListener('click', toggleSlideoverBattery);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#batteryButton3');
    toggleButton.addEventListener('click', toggleSlideoverBattery);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover-battery-bg');
    toggleButton.addEventListener('click', toggleSlideoverBattery);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover-battery');
    toggleButton.addEventListener('click', toggleSlideoverBattery);
});

// Privacy Policy

function toggleSlideoverPrivacypolicy() {
    document.getElementById('slideover-privacypolicy-container').classList.toggle('invisible');
    document.getElementById('slideover-privacypolicy-bg').classList.toggle('opacity-0');
    document.getElementById('slideover-privacypolicy-bg').classList.toggle('opacity-50');
    document.getElementById('slideover-privacypolicy').classList.toggle('translate-y-full');

    var element = document.getElementById("body");
    element.classList.toggle('noscroll');
}

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#privacypolicyButton');
    toggleButton.addEventListener('click', toggleSlideoverPrivacypolicy);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover-privacypolicy-bg');
    toggleButton.addEventListener('click', toggleSlideoverPrivacypolicy);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover-privacypolicy');
    toggleButton.addEventListener('click', toggleSlideoverPrivacypolicy);
});

// Gallery

function toggleSlideoverGallery(e) {

    console.log ("open");

    document.getElementById('slideover-gallery-container').classList.toggle('invisible');
    document.getElementById('slideover-gallery-bg').classList.toggle('opacity-0');
    document.getElementById('slideover-gallery-bg').classList.toggle('opacity-50');
    document.getElementById('slideover-gallery').classList.toggle('translate-y-full');

    var element = document.getElementById("body");
    element.classList.add('noscroll');

    var imageUrl = e.currentTarget.myParam;
    var altText = 'Description of the image';
    var imageElement = createImage(imageUrl, altText);

    var content = document.getElementById('gallery-content');
    content.innerHTML = "";
    content.appendChild(imageElement);
}

function closeSlideoverGallery(e) {

    console.log ("close");

    document.getElementById('slideover-gallery-container').classList.toggle('invisible');
    document.getElementById('slideover-gallery-bg').classList.toggle('opacity-0');
    document.getElementById('slideover-gallery-bg').classList.toggle('opacity-50');
    document.getElementById('slideover-gallery').classList.toggle('translate-y-full');

    var element = document.getElementById("body");
    element.classList.remove('noscroll');

    // var content = document.getElementById('gallery-content');
    // content.innerHTML = "";
    
}

function createImage(src, altText) {

    // content = document.getElementById('gallery-content');

    var img = document.createElement('img'); // Create a new img element
    img.src = src; // Set the src attribute of the img element
    img.alt = altText; // Set the alt attribute of the img element
    return img; // Return the created img element
    

}

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage1 = document.querySelector('#gallery-image-1');
    galleryImage1.myParam = gallery1;
    galleryImage1.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage2 = document.querySelector('#gallery-image-2');
    galleryImage2.myParam = gallery2;
    galleryImage2.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage3 = document.querySelector('#gallery-image-3');
    galleryImage3.myParam = gallery3;
    galleryImage3.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage4 = document.querySelector('#gallery-image-4');
    galleryImage4.myParam = gallery4;
    galleryImage4.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage5 = document.querySelector('#gallery-image-5');
    galleryImage5.myParam = gallery5;
    galleryImage5.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage6 = document.querySelector('#gallery-image-6');
    galleryImage6.myParam = gallery6;
    galleryImage6.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage7 = document.querySelector('#gallery-image-7');
    galleryImage7.myParam = gallery7;
    galleryImage7.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage8 = document.querySelector('#gallery-image-8');
    galleryImage8.myParam = gallery8;
    galleryImage8.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage9 = document.querySelector('#gallery-image-9');
    galleryImage9.myParam = gallery9;
    galleryImage9.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage10 = document.querySelector('#gallery-image-10');
    galleryImage10.myParam = gallery10;
    galleryImage10.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage11 = document.querySelector('#gallery-image-11');
    galleryImage11.myParam = gallery11;
    galleryImage11.addEventListener('click', toggleSlideoverGallery, false);
});

document.addEventListener('DOMContentLoaded', function () {
    var galleryImage12 = document.querySelector('#gallery-image-12');
    galleryImage12.myParam = gallery12;
    galleryImage12.addEventListener('click', toggleSlideoverGallery, false);
});


document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover-gallery-bg');
    toggleButton.addEventListener('click', closeSlideoverGallery);
});

document.addEventListener('DOMContentLoaded', function () {
    var toggleButton = document.querySelector('#slideover-gallery');
    toggleButton.addEventListener('click', closeSlideoverGallery);
});
